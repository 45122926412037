// src/components/About/About.js
import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';

const About = () => {
  return (
    <Box sx={{ py: 6, backgroundColor: '#f9f9f9' ,paddingTop:'100px' }}>
      <Container>
        {/* Company Overview Section */}
        <Typography variant="h3" gutterBottom align="center" color="primary">
          About Dsicon Enterprises Pvt Ltd
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Innovating the Future of Drone Technology and Surveillance Solutions
        </Typography>

        {/* Mission and Vision Section */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" color="primary" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            At Dsicon Enterprises Pvt Ltd, our mission is to harness the power of drone technology to enhance productivity, safety, and efficiency across industries. We aim to bring precision, reliability, and innovation to agriculture, surveillance, and infrastructure sectors.
          </Typography>

          <Typography variant="h4" color="primary" gutterBottom sx={{ mt: 4 }}>
            Our Vision
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            We envision a future where technology seamlessly integrates into daily life, optimizing processes and empowering businesses. Dsicon Enterprises Pvt Ltd strives to be a leader in drone-based solutions, setting the standard for quality, safety, and environmental stewardship.
          </Typography>
        </Box>

        {/* Core Services Section */}
        <Box sx={{ mt: 6 }}>
          <Typography variant="h4" gutterBottom align="center" color="primary">
            What We Offer
          </Typography>
          <Grid container spacing={4} sx={{ mt: 2 }}>
            {/* Drone Services */}
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <CardContent>
                  <Typography variant="h5" color="secondary" gutterBottom>
                    Drone Surveillance
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Offering advanced surveillance solutions for security, wildlife tracking, and emergency response. Our drones ensure real-time data and high-resolution monitoring for critical operations.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Agricultural Services */}
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <CardContent>
                  <Typography variant="h5" color="secondary" gutterBottom>
                    Agricultural Drone Services
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Precision agriculture solutions to enhance productivity. We offer crop monitoring, soil analysis, and precision spraying to ensure optimized farming practices and resource efficiency.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Infrastructure Inspection */}
            <Grid item xs={12} md={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <CardContent>
                  <Typography variant="h5" color="secondary" gutterBottom>
                    Infrastructure Inspection
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Comprehensive inspection services for bridges, buildings, and power lines using drone technology. We provide accurate data to help maintain safety and operational efficiency.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Call to Action */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Join Us in Pioneering the Future
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Contact us to learn more about our services and how Dsicon Enterprises Pvt Ltd can help your business reach new heights with drone technology.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default About;
