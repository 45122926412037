// src/components/Surveillance/Surveillance.js
import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import areaMonitoringImage from '../../Assets/area-monitoring.jpg'; // Replace with actual image path
import wildlifeTrackingImage from '../../Assets/wildlife-tracking.jpg';
import infrastructureInspectionImage from '../../Assets/infrastructure-inspection.jpg';
import emergencyResponseImage from '../../Assets/emergency-response.jpg';
// import borderPatrolImage from '../../Assets/border-patrol.jpg';
import pipelineInspectionImage from '../../Assets/pipeline-inspection.jpg'; // Replace with your pipeline inspection image path

import searchAndRescueImage from '../../Assets/search-and-rescue.jpg';

const Surveillance = () => {
  return (
    <Box sx={{ py: 6, backgroundColor: '#f9f9f9' ,paddingTop:'100px'}}>
      <Container>
        {/* Header Section */}
        <Typography variant="h3" gutterBottom align="center" color="primary">
          Drone Surveillance Services
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          Advanced surveillance solutions for diverse applications, ensuring safety and efficiency.
        </Typography>

        {/* Service Details Section */}
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Area Monitoring */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={areaMonitoringImage} // Use imported image or URL
                alt="Area Monitoring"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Area Monitoring
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Monitor large areas with real-time aerial views, ideal for managing events, overseeing construction sites, and enhancing perimeter security.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Wildlife Tracking */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={wildlifeTrackingImage} // Use imported image or URL
                alt="Wildlife Tracking"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Wildlife Tracking
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Safely observe and track wildlife without disturbing habitats, supporting conservation efforts and animal behavior studies.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Infrastructure Inspection */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={infrastructureInspectionImage} // Use imported image or URL
                alt="Infrastructure Inspection"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Infrastructure Inspection
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Conduct thorough inspections of bridges, buildings, and power lines with drone technology, identifying potential hazards with precision.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Emergency Response */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={emergencyResponseImage} // Use imported image or URL
                alt="Emergency Response"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Emergency Response
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Drones provide rapid aerial assessments during natural disasters or accidents, aiding emergency responders in coordinating efforts.
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Border Patrol
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={borderPatrolImage} // Use imported image or URL
                alt="Border Patrol"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Border Patrol
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Enhance border security with continuous aerial monitoring, detecting unauthorized activities over expansive and remote areas.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
 */}

            {/* Pipeline Inspection Card */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={pipelineInspectionImage} // Use imported image or URL
                alt="Border Patrol"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
              <Typography variant="h5" color="primary" gutterBottom>
                      Pipeline Inspection
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      High-resolution drone inspections to monitor and maintain the integrity of pipelines, detecting leaks and damages efficiently.
                    </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          {/* Search and Rescue */}
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image={searchAndRescueImage} // Use imported image or URL
                alt="Search and Rescue"
                sx={{ height: '100%', objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Search and Rescue
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Equipped with thermal cameras, drones assist in locating missing persons in challenging terrains and conditions.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Call to Action Section */}
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Secure Your Environment with Our Drone Surveillance
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Contact us today to learn more about our advanced drone surveillance solutions tailored to your security needs.
          </Typography>
          <Button variant="contained" color="primary" size="large" href="/contact">
            Get in Touch
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Surveillance;