// src/components/Contact/Contact.js
import React, { useState } from 'react';
import { Box, Typography, Container, Grid, TextField, Button, Paper, IconButton, Tooltip } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://dsiconenterprises-api.vercel.app/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setResponseMessage(data.message);
        setFormData({ name: '', email: '', contactNumber: '', message: '' });
        alert("message send")
        console.log("message send")
      } else {
        setResponseMessage(data.error || 'Something went wrong');
      }
    } catch (error) {
      setResponseMessage('Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ py: 6, backgroundColor: '#f9f9f9' ,paddingTop:'100px' }}>
      <Container>
        {/* Header Section */}
        <Typography variant="h3" gutterBottom align="center" color="primary">
          Contact Us
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary" paragraph>
          We'd love to hear from you! Fill out the form below or reach out using the contact details.
        </Typography>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          {/* Contact Form */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4 }}>
              <Typography variant="h5" gutterBottom>
                Send Us a Message
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate>
                <TextField
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#1E3A8A',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: 'black', // Change the input text color to black
                    },
                  }}
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  type="email"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#1E3A8A',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: 'black', // Change the input text color to black
                    },
                  }}
                />
                <TextField
                  label="Contact Number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  type="tel"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#1E3A8A',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: 'black', // Change the input text color to black
                    },
                  }}
                />
                <TextField
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  required
                  multiline
                  rows={4}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#1E3A8A',
                      },
                    },
                    '& .MuiInputBase-input': {
                      color: 'black', // Change the input text color to black
                    },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
              </Box>
              {responseMessage && (
                <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
                  {responseMessage}
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 4 }}>
              <Typography variant="h5" gutterBottom>
                Contact Information
              </Typography>
              <Typography variant="body1" color="text.secondary" paragraph>
                <strong>Dsicon Enterprises Pvt Ltd</strong>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Vill: Kuwarpur, Tehshel: Shikarpur, District: Bulandshahr, UP, 202396
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Phone: +91 8000606017
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Email: business@dsiconenterprises.com
              </Typography>

              <Typography variant="h6" sx={{ mt: 4 }}>
                Follow Us
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Tooltip title="Facebook" arrow>
                  <IconButton href="https://facebook.com" target="_blank" color="primary">
                    <FacebookIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Twitter" arrow>
                  <IconButton href="https://twitter.com" target="_blank" color="primary">
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="LinkedIn" arrow>
                  <IconButton href="https://linkedin.com" target="_blank" color="primary">
                    <LinkedInIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Contact;
